import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { useUser } from '@/composables/useUser';
import { memberAuth } from '@/middleware/index';

Vue.use(VueRouter);

const { isAuthenticated } = useUser();
const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'AdminLogin',
    component: () => import(/* webpackChunkName: "AdminLogin" */ '@/pages/admin/Login.vue'),
    meta: {
      disableIfLoggedIn: true,
    },
    beforeEnter: (to, from, next) => memberAuth(to, from, next),
  },
  {
    path: '/',
    redirect: () => {
      if (isAuthenticated) return '/order-management';
      return '/login';
    },
    component: () => import(/* webpackChunkName: "AdminPage" */ '@/pages/admin/View.vue'),
    children: [
      {
        path: 'gift-management',
        name: 'GiftManagement',
        component: () =>
          import(/* webpackChunkName: "GiftManagement" */ '@/pages/admin/gift/Management.vue'),
        beforeEnter: (to, from, next) => memberAuth(to, from, next),
      },
      {
        path: 'maker-management',
        name: 'MakerManagement',
        component: () =>
          import(/* webpackChunkName: "MakerManagement" */ '@/pages/admin/maker/Management.vue'),
        beforeEnter: (to, from, next) => memberAuth(to, from, next),
      },
      {
        path: 'order-management',
        name: 'OrderManagement',
        component: () =>
          import(
            /* webpackChunkName: "OrderManagement" */ '@/pages/admin/order-form/Management.vue'
          ),
        beforeEnter: (to, from, next) => memberAuth(to, from, next),
        meta: {
          isOrderScreen: true,
        },
      },
      {
        path: 'create-order',
        name: 'CreateOrderForm',
        component: () =>
          import(/* webpackChunkName: "CreateOrderForm" */ '@/pages/admin/order-form/Create.vue'),
        beforeEnter: (to, from, next) => memberAuth(to, from, next),
        meta: {
          isOrderScreen: true,
        },
      },
      {
        path: 'detail-order/:id',
        name: 'DetailOrderForm',
        component: () =>
          import(/* webpackChunkName: "DetailOrderForm" */ '@/pages/admin/order-form/Create.vue'),
        beforeEnter: (to, from, next) => memberAuth(to, from, next),
        meta: {
          isOrderScreen: true,
        },
      },
    ],
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "NotFound" */ '@/layouts/other/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
